import { useStaticQuery, graphql } from "gatsby"

const queryServizi = () => {
  const data = useStaticQuery(graphql`
    {
      servizi: allWordpressWpServizio {
        edges {
          node {
            id
            slug
            acf {
              descrizione
              sottotitolo
              titolo
              header {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 1980, quality: 80) {
                      aspectRatio
                      base64
                      sizes
                      src
                      srcSet
                    }
                  }
                }
              }
              __typename
              gruppo_servizi {
                servizio
              }
            }
          }
        }
      }
    }
  `)

  return data.servizi.edges
}

export default queryServizi
