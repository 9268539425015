import React from "react"
import TransitionBehavior from "../components/TransitionBehavior/TransitionBehavior"
import { Formik, Form, Field, ErrorMessage } from "formik"
import styles from "./contatti.module.scss"
import Heading from "../components/Heading/Heading"
import queryServizi from "../queries/queryServizi"
import HeaderImg from "../components/HeaderImg/HeaderImg"
import Header from "../components/Header/Header"
import Footer from "../components/Footer/Footer"
import addToMailchimp from "gatsby-plugin-mailchimp"
import axios from "axios"

export default function Contatti({ transitionStatus }) {
  let transitionClass = "transitionContents " + transitionStatus
  const servizi = queryServizi()
  return (
    <TransitionBehavior>
      <div className={transitionClass}>
        <Header />
        <HeaderImg tipoheader={"testataStandard"}>
          <Heading
            title={"Contatti"}
            subtitle={
              "Cerchi un partner che acceleri il tuo prossimo progetto?"
            }
            ish1={true}
          ></Heading>
        </HeaderImg>
        <div className={[styles.containerMedium, styles.contatti].join(" ")}>
          <div className={styles.sezioneLeft}>
            <h1>Scrivici prima che lo faccia un tuo competitor!</h1>
            <p>
              Siamo un agenzia radicalmente diversa dalle altre là fuori.
              Abbiamo aiutato decine di team ambiziosi a realizzare il loro
              potenziale in progetti complessi di innovazione tecnica e
              strategica.
            </p>
            <p>
              {" "}
              Puoi farci una semplice domanda o parlarci del tuo prossimo
              progetto, siamo qui per aiutarti a realizzare l’impatto sul
              business di cui hai bisogno.
            </p>
            <div className={styles.indirizzi}>
              <div>
                <p>
                  <b>FIRENZE</b>
                </p>
                <p>
                  Via Giovanni <br /> del Pian dei Carpini, 1
                </p>
                <p>50127 Firenze</p>
              </div>
              <div>
                <p>
                  <b>AREZZO</b>
                </p>
                <p>Via Pietro Calamandrei, 129</p>
                <p>52100 Arezzo</p>
              </div>
            </div>
          </div>
          <div className={styles.sezioneRight}>
            <h2>Per far crescere la mia azienda ho bisogno di:</h2>
            <Formik
              validateOnChange={true}
              className={styles.form}
              initialValues={{
                nome: "",
                cognome: "",
                email: "",
                telefono: "",
                sito: "",
                servizio: "",
                areaTesto: "",
              }}
              validate={values => {
                let errors = {}
                if (!values.email) {
                  errors.email = "Campo obbligatorio"
                } else if (
                  !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
                ) {
                  errors.email = "Email non valida"
                }
                return errors
              }}
              onSubmit={(values, { setSubmitting, setStatus }) => {
                const informazioni = `**Nome:** ${values.nome}
                **Cognome:** ${values.cognome}
                **Email:** ${values.cognome}
                **Telefono:** ${values.telefono}
                **Sito:** ${values.sito}
                **Servizio:** ${values.servizio}
                **Note:** ${values.areaTesto}`
                setStatus({
                  success: "Spedisco email...",
                  css: "sending",
                })
                addToMailchimp(values.email, {
                  TESTO: informazioni,
                  TAG: "contactform",
                }) // listFields are optional if you are only capturing the email address.
                  .then(data => {
                    let auth = "Basic ce0954eaed527dbd8e8f75baa3bd21e3-us20"
                    axios({
                      method: "post",
                      url: `https://us20.api.mailchimp.com/3.0/lists/f92900b747/segments/80361/members`,
                      headers: {
                        Authorization: auth,
                      },
                      data: {
                        email_address: values.email,
                      },
                    })
                    console.log("data", data)
                    if (data.result === "success") {
                      setStatus({
                        success: "Email inviata !",
                        css: "success",
                      })
                    } else {
                      setStatus({
                        success: "Ci sono degli errori, email non inviata!",
                        css: "error",
                      })
                    }
                  })
                axios
                  .post(
                    `https://api.trello.com/1/cards?idList=5dce5fcd61964d74e4ced56a&keepFromSource=all&name=${values.email}&desc=${informazioni}&key=603b15433479f47ded0cd4af7184c1c8&token=5cc4cee9caa753f26370c67d73f182b092b5f668afcf71e04f3234e0406c77ac`
                  )
                  .then(res => {
                    console.log(res)
                    console.log(res.data)
                  })
                  .catch(err => {
                    alert("Error  : " + err)
                  })
                setTimeout(() => {
                  //   alert(JSON.stringify(values, null, 2))
                  setSubmitting(false)
                }, 400)
              }}
            >
              {({ isSubmitting, status }) => (
                <Form>
                  <Field component="select" name="servizio">
                    <option value="" label="Scegli un servizio">
                      Scegli un servizio
                    </option>
                    {servizi.map(servizio => {
                      const { titolo } = servizio.node.acf
                      return (
                        <option key={titolo} value={titolo} label={titolo}>
                          {titolo}
                        </option>
                      )
                    })}
                  </Field>
                  <Field type="text" name="nome" placeholder="Nome" />
                  <ErrorMessage name="nome" component="span" />
                  <Field type="text" name="cognome" placeholder="Cognome" />
                  <ErrorMessage name="cognome" component="span" />
                  <Field type="email" name="email" placeholder="Email" />
                  <ErrorMessage name="email" component="span" />
                  <Field type="text" name="telefono" placeholder="Telefono" />
                  <ErrorMessage name="telefono" component="span" />
                  <Field type="text" name="sito" placeholder="Sito Web" />
                  <ErrorMessage name="sito" component="span" />
                  <Field
                    component="textarea"
                    maxLength="500"
                    name="areaTesto"
                    placeholder="Raccontaci del progetto e quale are area ha necessità di essere migliorata..."
                  />
                  <ErrorMessage name="areaTesto" component="span" />
                  {console.log(status)}
                  <div className={status ? status.css : ""}>
                    {status ? status.success : ""}
                  </div>
                  <button type="submit" disabled={isSubmitting}>
                    CONTATTACI
                  </button>
                </Form>
              )}
            </Formik>
          </div>
        </div>
        <Footer />
      </div>
    </TransitionBehavior>
  )
}
